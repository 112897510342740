(function($) {
// Imported from original site
if( $('body').hasClass('due-date-calculator') ) {
    document.addEventListener('DOMContentLoaded', function () {
        var status = document.getElementById('b_calculator__status');
        var btn = document.getElementById('dueDateBtn');
        var fireworks;

        // Start jquery datepicker
        $("#datepicker").datepicker().datepicker('setDate', new Date());
        // Remove Class to show calendar
        $('#ui-datepicker-div').removeClass('ui-helper-hidden-accessible');
        // Setup Calculator dropdown
        setup('b_calculator__dropdown');

        if(!btn) {
            return;
        } else {
            btn.onclick = function () {

            // Fireworks
            window.clearTimeout(fireworks);
            $('body').append('<div class="pyro"><div class="before"></div><div class="after"></div></div>');
            fireworks = window.setTimeout(function(){ $('.pyro').remove(); }, 6000);

                var number = 252;
                var dropdownValue = getDropdownValueFrom('b_calculator__dropdown');
                var dateValue = getJqueryDate();

                var answer = number + parseInt(dropdownValue) + dateValue;
                var answer2 = Math.floor(answer / 365);

                var innerHTML = '<div class="bg-purple due-date-result"><span class="b_calculator__congrats">Congratulations!</span>'

                if(dropDownYear() == currentYear() - 1) {
                  innerHTML += getYear(answer % 365, 0);
                } else if (answer2 == 0) {
                  innerHTML += getYear(answer, 0);
                } else {
                  innerHTML += getYear(answer % 365, 1);
                }

                status.innerHTML = innerHTML + '</div>';

            }
        }

    });

    //Setup Dropdown Selection
    function setup(x) {
        var dropdownValue = document.getElementById(x);
        if(!dropdownValue) {
            return;
        }
        const min = 20;
        const max = 45;
        for (var i = min; i <= max; i++) {
            var option = document.createElement('option');
            option.value = i;
            option.innerHTML = i;
            dropdownValue.appendChild(option);
            if (option.value == 28) {
                option.setAttribute('selected', '');
            }
        }
    }

    function getDropdownValueFrom(x) {
        return document.getElementById(x).value;
    }

    function getJqueryDate() {
        var date = $("#datepicker").datepicker('getDate');
        if (date == null) {
            return false;
        }
        var start = new Date(date.getFullYear(), 0, 0);
        var diff = date - start;
        var oneDay = 1000 * 60 * 60 * 24;
        var day = Math.floor(diff / oneDay);
        return day;
    }

    function dropDownYear() {
        var date = $("#datepicker").datepicker('getDate');
        return date.getFullYear();
    }

    function getYear(x, y) {
        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        var thisYear = new Date().getFullYear() + y;
        var date = new Date(thisYear, 0);

        var d = new Date(date.setDate(x));
        var dayValue = days[d.getDay()];
        var monthValue = months[d.getMonth()];
        var dateValue = d.getDate();
        var yearValue = d.getFullYear();
        var fullDate = dayValue + ", " + monthValue + " " + dateValue + ", " + yearValue;

        return fullDate;
        //return new Date(date.setDate(x));
    }

    function currentYear() {
        var date = new Date().getFullYear()
        return date;
    }
}

})(jQuery);
